
.links__container {
    display: flex;
    grid-gap: 15px;
    align-items: flex-end;
    justify-self: flex-end;
    box-sizing: border-box;
    padding-right: 135px;
    padding-bottom: 50px;
}

@media screen and (max-width: 1090px) {
    .links__container {
        padding-right: 0;
        justify-self: center;
    }
}

.link:hover {
    cursor: pointer;
    opacity: 1.5;
    transition: .8s;
    transform: scale(1.1); /* увеличиваем изображение при ховере */
}

.links__image-facebook {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    background-image: url(../../images/facebook.svg);
}

.links__image-telegram {
    width: 30px;
    opacity: 0.5;
    height: 30px;
    background-image: url(../../images/telegram.svg);
}

.links__image-instagram {
    width: 30px;
    height: 30px;
    /* opacity: 0.5; */
    background-image: url(../../images/instagram.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.links__image-whatsapp {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    background-image: url(../../images/whatsApp.svg);
}