.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    overflow-y: auto;
    scrollbar-width: none;
    border-radius: 8px;
  }

  @media screen and (max-width: 1090px) {
    .popup-content {
      width: 80%;
    }
  }

  .close {
    background-image: url(../../images/X.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    cursor: pointer;
    color: #fff;
    width: 30px;
    height: 30px;
    border: none;
    position: absolute;
    top: 10px; /* Позиция сверху */
    right: 10px; /* Позиция справа */
    transition: 0.3s;
  }

  .close:hover {
    opacity: 1;
    transform: scale(1.2);
    transform-origin: center;
  }