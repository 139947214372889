.promo {
    display: grid;
    grid-template-areas: 
        'promo-title'
        'promo-summary';
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(../../images/3_rakurs.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 95vh;
    /* max-width: 1440px; */
    /* margin: 0 auto; */
    width: 100%;
}

@media screen and (max-width: 1090px) {
    .promo {
        grid-template-areas: 
        "promo-title"
        "promo-summary";
        /* "promo-links"; */
        justify-content: center;
        height: auto;
        justify-self: center;

    }
}

.promo__title {
    font-family: Halvar Breitschrift;
    font-size: 52px;
    font-weight: 800;
    line-height: 70px;
    color: #fff;    
    grid-area: promo-title;
    text-indent: 40px;
    padding-top: 60px;
    padding-right: 135px;
    /* padding-bottom: 321px; */
    margin-top: 0;
    margin-bottom: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-align: end;
}

@media screen and (max-width: 1090px) {
    
    .promo__title {
        text-align: center;
        padding-top: 100px;
        padding-left: 0;
        padding-bottom: 410px;
        text-indent: 0;
        font-size: 27px;
        line-height: 32.4px;
        padding-right: 0;
    }
}

@media screen and (max-width: 380px) {
    .promo__title {
        padding-bottom: 150px;
    }
}

.promo__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.6px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 30px;
    color: #99A0A7;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.promo__button {
    width: 122px;
    height: 47px;
    border: 1px solid black;
    background: transparent;
    margin: 0;
}

.promo__summary {
    display: grid;
    grid-template-columns: repeat(3, auto);
    list-style-type: none;
    grid-area: promo-summary;
    margin: 0;
    padding-left: 135px;
    gap: 30px;
    /* width: 230px; */
    box-sizing: border-box;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* justify-items: end; */
    align-items: center;
    justify-content: start;
}

@media screen and (max-width: 1090px) {
    .promo__summary {
        padding-left: 10px;
        gap: 18px;
        width: 230px;
        justify-items: unset;
        justify-content: unset;
        padding-bottom: 50px;
    }
    
}

@media screen and (max-width: 380px) {
    .promo__summary {
        grid-template-columns: 1fr;
        margin: 0 auto;
        padding-left: 50px;
    }
    
}

.promo__summary-point {
    padding-top: 20px;
    padding-left: 43px;
    position: relative;
}

@media screen and (max-width: 1090px) {
    .promo__summary-point {
        padding-left: 20px;
    }
}

.promo__summary-point::before {
    content: url(../../images/Frame.svg);
    position: absolute;
    top: -2px;
    left: 2px;
    scale: 1.5;
}

.promo__summary-title {
    margin: 0;
    font-family: Halvar Breitschrift;
    font-size: 52px;
    font-weight: 700;
    line-height: 55px;
    color: #fff;
}

@media screen and (max-width: 1090px) {
    .promo__summary-title {
        font-size: 30.66px;
        line-height: 45.99px;
    }
}

.promo__summary-text {
    margin: 0;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
    white-space: nowrap;
}

@media screen and (max-width: 1090px) {
    .promo__summary-text {
        font-weight: 500;
        font-size: 8.59px;
        line-height: 9.94px;
    }
}

/* .promo__links {
    grid-area: promo-links;
    align-self: end;
} */