.App {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}

h2, h3, p, ul, li {
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
}
