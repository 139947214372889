@import "./vendor/normalize.css";
@import "./vendor/fonts/font.css";

body {
  margin: 0;
  font-family: Halvar Breitschrift, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body._lock {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link {
  text-decoration: none;
}

img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}