.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.1); 
    background-color: #fff;
}

.header__container {
    display: flex;
    /* max-width: 1440px; */
    margin: 0 auto;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    padding-left: 135px;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 1090px) {
    .header__logo {
        padding-left: 30px;
    }
}

@media screen and (max-width: 768px) {
    .header__logo {
        padding-left: 16px;
    }

    .menu__icon {
        margin-right: 16px;
    }
}

.header__logo-img {
    transition: transform .3s ease;;
}

.header__logo-img:hover {
    transform: scale(1.08);
}


.header__logo-quote {
    font-family: Halvar Breitschrift;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    
    width: 116px;
    padding-left: 30px;
    align-self: center;
    color: #6D7781;
}

@media screen and (max-width: 768px) {
    .header__logo-quote {
        display: none;
    }
}

.header__button {
    font-family: Halvar Breitschrift;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    align-self: center;
    width: 193px;
    height: 44px;
    border: 1px solid #1A1A18;
    background-color: transparent;
    margin-right: 135px;
}

.header__button:hover {
    border: none;
    background-color: #2A5E38;
    color: #fff;
    transition: .8s;
}

.header__menu {
    padding-right: 0;
}

@media screen and (min-width: 1090px) {
    .header__menu {
        display: none;
    }
}

.menu {
}

.menu__icon {
    display: none;
}

@media screen and (max-width: 1090px) {
    .header__button {
        margin-right: 173px;
    }

    @media screen and (max-width: 768px) {
        .header__button {
            margin-right: 25px;
        }
    }
    
    .header__container {
        justify-content: space-between;

    }
    
    .menu__icon {
        z-index: 5;
        display: block;
        position: relative;
        width: 30px;
        height: 18px;
        cursor: pointer;
        margin: 30px;
    }

    @media screen and (max-width: 768px) {
        .menu__icon {
            margin-right: 16px;
        }
    }

    .menu__icon span,
    .menu__icon::before,
    .menu__icon::after {
        left: 0 ;
        position: absolute;
        height: 10%;
        width: 100%;
        transition: all .3s ease 0s;
        background-color: black;
    }

    .menu__icon::before,
    .menu__icon::after {
        content: "";
    }

    .menu__icon::before {
        top: 0;
    }

    .menu__icon::after {
        bottom: 0;
    }

    .menu__icon span {
        top: 50%;
        transform: scale(1) translate(0px, -50%);
    }

    .menu__icon._active span {
        transform: scale(0) translate(0px, -50%);
    }

    .menu__icon._active::before {
        top: 50%;
        transform: rotate(-45deg) translate(0px, -50%);
    }
    .menu__icon._active::after {
        bottom: 50%;
        transform: rotate(45deg) translate(0px, 50%);
    }
}