.button-more {
    margin: 30px 0 0px;
    width: 240px;
    height: 36px;
    border: 1px solid #424242;
    background-color: transparent;
    justify-self: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .button-more {
        width: 320px;
        height: 36px;
    }
}

.button-more:hover {
    background-color: #2A5E38;
    color: white;
    border: 1px solid #2A5E38;
}

.button-more--inactive {
    display: none;
  }