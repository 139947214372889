.checkbox {
    grid-area: checkbox;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    column-gap: 20px;
    align-items: center;
    padding-right: 135px;
}

@media screen and (max-width: 768px) {
    .checkbox {
        padding-right: 0;
        padding-bottom: 20px;
    }
}

.checkbox__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    transition: color .3s, border-bottom .3s;
}

@media screen and (min-width: 768px) {
    .checkbox__text {
        font-size: 14px;
    }
}

.checkbox__text:hover {
    transform: scale(1.1);
    color: #2A5E38;
    border-bottom: 1px solid #2A5E38;
}

.checkbox__text--active {
    color: #2A5E38;
    border-bottom: 1px solid #2A5E38;
    transform: scale(1.1);
    font-weight: 500;
}