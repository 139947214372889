.leadership {
    display: grid;
    grid-template-areas: 
        "title controls"
        "gallery gallery";
    /* margin: 0 auto; */
    /* max-width: 1440px; */
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #E0E0E0;
  }

  @media screen and (max-width: 1090px) {
    .leadership {
        margin: 0;
    }
}
  
  .leadership__title {
    grid-area: title;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    /* max-width: 720px; */
    height: 144px;
    padding-top: 51px;
    padding-left: 135px;
    padding-bottom: 49px;
    box-sizing: border-box;
    border-right: 1px #E0E0E0 solid;
    border-bottom: 1px #E0E0E0 solid;
  }


@media screen and (max-width: 1090px) {
  .leadership__title {
      padding-left: 30px;
      padding-top: 35px;
      padding-bottom: 35px;
      font-size: 28px;
      font-weight: 700;
      line-height: 30.8px;
      height: 105px;
  }
}

@media screen and (max-width: 768px) {
  .leadership__title {
      border-right: none;
      content: 'Руководство';
  }
}

@media screen and (max-width: 440px) {
  .leadership__title {
    font-size: 20px;
    padding-left: 16px;
  }
}
  
  .leadership-controls {
    grid-area: controls;
    height: 144px;
    box-sizing: border-box;
    padding-top: 50px;
    padding-right: 135px;
    justify-content: flex-end;
    border-bottom: 1px #E0E0E0 solid;
    display: flex;
    gap: 10px;
  }

  @media screen and (max-width: 1090px) {
    .leadership-controls {
        padding-right: 30px;
        padding-top: 35px;
        padding-bottom: 35px;
        height: 105px;
    }
}

@media screen and (max-width: 440px) {
  .leadership-controls {
    padding-right: 16px;
  }
}
  
  .prev {
    width: 44px;
    height: 44px;
    border: 1px;
    border-radius: 50%;
    border: 1px solid #285E38;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20.45px 20.45px;
    background-image: url(../../images/Arrow\ -\ Left-green.svg);
    background-color: transparent;
    transition: .3s;
  }

  .prev:hover {
    background-color: #285E38;
    border: 1px solid #fff;
    background-image: url(../../images/Arrow\ -\ Left.svg);
    transition: .3s;
}

.next:hover {
    background-color: #285E38;
    border: 1px solid #fff;
    background-image: url(../../images/Arrow\ -\ Right.svg);
    transition: .3s;
}
  
  .next {
    width: 44px;
    height: 44px;
    border: 1px;
    border-radius: 50%;
    border: 1px solid #285E38;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20.45px 20.45px;
    background-image: url(../../images/Arrow\ -\ Right-green.svg);
    background-color: transparent;
    transition: .3s;
}

.leadership__gallery {
    grid-area: gallery;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 135px;
    border-bottom: 1px #E0E0E0 solid;
    grid-gap: 30px;
    justify-items: center;
}

  @media screen and (max-width: 1090px) {
    .leadership__gallery {
       grid-template-columns: repeat(2, 1fr); 
       padding: 30px;
       grid-gap: 20px;
     }
   }
   
   @media screen and (max-width: 768px) {
    .leadership__gallery {
       grid-template-columns: repeat(2, 1fr); 
       /* padding: 16px;  */
       grid-gap: 15px;
     }
   }

   @media screen and (max-width: 440px) {
    .leadership__gallery {
        padding: 16px;
    } 
  }

.leadership__item {
    display: grid;
    max-width: 270px;
    align-content: baseline;
}


.leader-image {
  max-width: 100%;
  max-height: 388px;
  /* height: 100vh; */
  cursor: pointer;
  overflow: hidden; 
  transition: transform 0.3s ease;
  object-fit: cover;
  border-radius: 10px;
}

.leader-image:hover {
  transform: scale(1.05); 
}

.leader-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    padding-top: 15px;
    padding-bottom: 5px;
    max-width: 270px;
}
.leadership__item:hover .leader-name {
  color: #2A5E38;
}

.leader-caption {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: #6D7781;
    max-width: 270px;
}

.leadership-popup {
  display: grid;
  grid-template-areas:  
    "main description";
  grid-gap: 30px;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
}

@media screen and (max-width: 1090px) {
  .leadership-popup {
    grid-gap: 10px;
  }
}

@media screen and (max-width: 440px) {
  .leadership-popup {
    grid-template-areas: 
    'main'
    'description';
    padding: 16px;
    grid-gap: 30px;
  }
}

.leader-main {
  grid-area: main;
  max-width: 270px;
}

@media screen and (max-width: 440px) {
  .leader-main {
    display: flex;
    flex-direction: column;

  }
}

.leader-description {
  grid-area: description;
  max-width: 410px;
}

@media screen and (max-width: 768px) {
  .leader-description {
    display: flex;
    flex-direction: column;
  }
}

.leader-captions {
  font-family: Halvar Breitschrift;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A1A18;
  padding-bottom: 15px;
}

.leader-text {
  font-family: Halvar Breitschrift;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #1A1A18;
  opacity: 70%;
  padding-left: 15px;
}

.text-point {
  padding-bottom: 15px;
}
