.footer {
    display: grid;
    padding-top: 30px;
    position: relative;
    margin: 0 auto;
    /* max-width: 1440px; */
    width: 100%;
}

@media screen and (max-width: 768px) {
    .footer {

    }
}

.footer__layout {
    width: 100%;
    /* max-width: 1440px; */
    height: 435px;
}

.footer-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 100%;
    /* max-width: 1440px; */
    height: 435px;
    position: absolute;
    margin-top: 30px;
    pointer-events: none;
}

.footer__contacts {
    width: 268px;
    height: 303px;
    background-color: #fff;
    margin-top: 86px;
    margin-left: 135px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: 1px 1px 10px -5px black;
}

@media screen and (max-width: 768px) {
    .footer__contacts {
        position: relative;
        margin: 33px 16px;
        width: auto;
        height: auto;
        border: 1px solid #E0E0E0;
        padding-bottom: 30px;
    }
}

.footer__contacts-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px #E0E0E0 solid;

}

.footer__contacts-item {
    list-style: none;
    padding-left: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.contacts-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(109, 119, 129, 1);
    padding-top: 20px;
    padding-bottom: 5px;
}


.contacts-item {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.21px;
    letter-spacing: 0em;
    text-align: left;
    ;
    }

.contacts-item:hover {
    color: #285E38;
    cursor: default;
    transition: .5s;
}

.footer__copyrights {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 135px;
    align-items: center;
}

@media screen and (max-width: 1090px) {
    .footer__copyrights {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 400px) {
    .footer__copyrights {
        justify-content: center;
    }
}

.footer__logo {
    width: 108px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: contain;
}

.footer__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6D7781;
}

@media screen and (max-width: 768px) {
    .footer__text {
        display: none;
    }
}

.developed-by {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 400px) {
    .developed-by {
        display: none;
    }
}

.dev-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #6D7781;
    padding-right: 9px;
}

.dev-logo {
    background-repeat: no-repeat;
    background-size: contain;
    width: 92px;
    height: 22px;
}
