.projects__card {
    position: relative;
    list-style: none;
    cursor: pointer;
    max-width: 270px;
    height: 344px;
  }

  @media screen and (max-width: 768px) {
    .projects__card {
        max-width: 100%;
        max-height: 100%;
    }
  }
  
  .project__card-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    /* border-radius: 15px; */
  }
  
  .project__card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Задайте цвет подложки по вашему выбору */
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    color: white; /* Задайте цвет текста по вашему выбору */
    /* border-radius: 15px; */
    transition: background-color 0.3s ease; /* Smooth transition */

  }

  .project__card-overlay:hover {
    background-color: transparent;
  }

  .project__card-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 20.84px;
  }

  .project__card-location {
    font-size: 12px;
    font-weight: 400;
    line-height: 13.9px;
    padding-bottom: 24px;
  }
  
  .project__card-title,
  .project__card-location {
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 2);
  }