.nav__body {
    display: flex;
    align-items: center;
    gap: 30px;
    box-sizing: border-box;
    padding-left: 130px;
    padding-right: 135px;
}

@media screen and (max-width: 1090px) { 
    .nav__body {
        display: none;
    }

    .nav__body._active {
        display: flex;
        position: fixed;
        top: 0;
        left: 30%;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .9);
        transition: left .3s ease 0s;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .nav__body._active {
        left: 0;
        align-items: center;
    }
}

.nav__link {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    color: #1A1A18;
    text-decoration: none;
}

.nav__link:hover {
    /* cursor: pointer;
    opacity: 0.2; */
    color: #2A5E38;
    border-bottom: 2px solid #2A5E38;
    transition: background-color 0.8s, color 0.8s; 
}

.nav__link.active {
    border-bottom: 2px solid #2A5E38;
    color: #2A5E38;

  }

  .navigation__button {
    width: 38px;
    height: 38px;
    justify-self: end;
    background-image: url(../../images/List.svg);
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
}  

@media screen and (min-width: 768px) {
    .navigation__button {
        width: 44px;
        height: 44px;
    }
}

@media screen and (min-width: 1280px) {
    .navigation__button {
        display: none;
    }
}

.navigation__button_type_close {
    padding: 0;
    width: 58px;
    height: 58px;
    background-image: url(../../images/Xblack.svg);
    background-size: 30px;
    background-position: center;
}

@media screen and (min-width: 768px) {
    .navigation__button_type_close {
        width: 74px;
        height: 74px;
    }
}
