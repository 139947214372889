.projects {
    display: grid;
}

.projects-header {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

@media screen and (max-width: 768px) {
    .projects-header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.projects__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    padding-left: 135px;
}

@media screen and (max-width: 768px) {
    .projects__title {
        padding-left: 0;
        padding-bottom: 20px;
    }
}

.projects__checkbox {
    padding-right: 135px;
}