.gallery-slider {
  display: grid;
  grid-template-areas: 
    "title controls"
    "gallery gallery";
  grid-template-columns: 1fr 1fr;
  background-color: rgb(145, 145, 145);
}

.gallery-slider__title {
  grid-area: title;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  height: 144px;
  padding-top: 51px;
  padding-left: 135px;
  padding-bottom: 49px;
  box-sizing: border-box;
  border-right: 1px #E0E0E0 solid;
  border-bottom: 1px #E0E0E0 solid;
  color: #fff;
}

@media screen and (max-width: 1090px) {
  .gallery-slider__title {
    padding-left: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    height: 105px;
  }
}

@media screen and (max-width: 768px) {
  .gallery-slider__title {
    border-right: none;
  }
}

@media screen and (max-width: 440px) {
  .gallery-slider__title {
    padding-left: 16px;
  }
}

.controls {
  grid-area: controls;
  height: 144px;
  box-sizing: border-box;
  padding-top: 50px;
  padding-right: 135px;
  justify-content: flex-end;
  border-bottom: 1px #E0E0E0 solid;
  display: flex;
}

@media screen and (max-width: 1090px) {
  .controls {
    padding-right: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    height: 105px;
  }
}

@media screen and (max-width: 440px) {
  .controls {
    padding-right: 16px;
  }
}

.arrows {
  display: flex;
  gap: 10px;
}

.button_prev, .button_next {
  width: 44px;
  height: 44px;
  border: 1px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20.45px 20.45px;
  background-color: transparent;
  transition: .3s;
}

.button_prev {
  background-image: url(../../images/Arrow\ -\ Left.svg);
}

.button_next {
  background-image: url(../../images/Arrow\ -\ Right.svg);
}

.button_prev:hover, .button_next:hover {
  border: 1px solid #285E38;
  background-color: #fff;
  transition: .3s;
}

.button_prev:hover {
  background-image: url(../../images/Arrow\ -\ Left-green.svg);
}

.button_next:hover {
  background-image: url(../../images/Arrow\ -\ Right-green.svg);
}

.gallery-slider__gallery {
  grid-area: gallery;
  padding: 30px 135px;
}

@media screen and (max-width: 1090px) {
  .gallery-slider__gallery {
    padding: 30px;
  }
}

@media screen and (max-width: 768px) {
  .gallery-slider__gallery {
    padding: 15px;
  }
}

@media screen and (max-width: 440px) {
  .gallery-slider__gallery {
    padding: 16px;
  }
}

.gallery-slider__items {
  display: grid;
  position: relative;
  max-width: 270px;
  height: 344px;
  overflow: hidden;
  cursor: pointer;
}

.slider-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.popup-image {
  max-width: 80%;
  max-height: 80%;
}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 4rem;
  color: white;
  cursor: pointer;
  opacity: .5;
  padding: 50px;
  transition: 0.3s;
}

@media screen and (max-width: 1090px) {
  .arrow {
    padding: 16px;
  }
}

@media screen and (max-width: 768px) {
  .arrow {
    padding: 10px;
  }
}

@media screen and (max-width: 440px) {
  .arrow {
    padding: 0;
  }
}

.arrow:hover {
  opacity: 1;
  transform: scale(1.2);
  transition: 0.3s;
}

.close-popup-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
