.swiper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.swiper-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.swiper-slide {
  width: auto !important;
  height: 344px !important;
}

.projects-slider {
  display: grid;
  grid-template-areas: 
    "title controls"
    "gallery gallery";
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1090px) {
 .projects-slider {
    margin: 0;
  }
}

.projects-slider__title-link {
  text-decoration: none;
  color: #1A1A18;
  cursor: default;
}

.projects-slider__title {
  grid-area: title;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  height: 144px;
  padding-top: 51px;
  padding-left: 135px;
  padding-bottom: 49px;
  box-sizing: border-box;
  border-right: 1px #E0E0E0 solid;
  border-bottom: 1px #E0E0E0 solid;
}

@media screen and (max-width: 1090px) {
 .projects-slider__title {
    padding-left: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    height: 105px;
  }
}

@media screen and (max-width: 768px) {
 .projects-slider__title {
    border-right: none;
  }
}

@media screen and (max-width: 440px) {
 .projects-slider__title {
    padding-left: 16px;
  }
}

.projects-slider__title span {
  cursor: pointer; 
}

.controls {
  grid-area: controls;
  height: 144px;
  box-sizing: border-box;
  padding-top: 50px;
  padding-right: 135px;
  justify-content: flex-end;
  border-bottom: 1px #E0E0E0 solid;
  display: flex;
}

@media screen and (max-width: 1090px) {
 .controls {
    padding-right: 30px;
    padding-top: 35px;
    padding-bottom: 35px;
    height: 105px;
  }
}

@media screen and (max-width: 440px) {
 .controls {
    padding-right: 16px;
  }
}

.arrows {
  display: flex;
  gap: 10px;
}

.prev {
  width: 44px;
  height: 44px;
  border: 1px;
  border-radius: 50%;
  border: 1px solid #285E38;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20.45px 20.45px;
  background-image: url(../../images/Arrow\ -\ Left-green.svg);
  background-color: transparent;
  transition:.3s;
}

.next {
  width: 44px;
  height: 44px;
  border: 1px;
  border-radius: 50%;
  border: 1px solid #285E38;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20.45px 20.45px;
  background-image: url(../../images/Arrow\ -\ Right-green.svg);
  background-color: transparent;
  transition:.3s;
}

.more {
  font-size: 14px;
  line-height: 16.21px;
  text-decoration: none;
  color: #99A0A7;
  height: fit-content;
  padding-right: 30px;
  padding-top: 15px;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 505px) {
 .more {
    display: none;
  }
}

.more:hover {
  transform: scale(1.05);
  text-decoration: underline;
  transition:.3s;
}

.prev:hover {
  background-color: #285E38;
  border: 1px solid #fff;
  background-image: url(../../images/Arrow\ -\ Left.svg);
  transition:.3s;
}

.next:hover {
  background-color: #285E38;
  border: 1px solid #fff;
  background-image: url(../../images/Arrow\ -\ Right.svg);
  transition:.3s;
}

.projects-slider__gallery {
  grid-area: gallery;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 30px 135px;
  border-bottom: 1px #E0E0E0 solid;
  grid-gap: 30px;
  justify-items: center;
  transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 1090px) {
 .projects-slider__gallery {
    grid-template-columns: repeat(3, 1fr);
    padding: 30px;
    grid-gap: 20px;
  }
}

@media screen and (max-width: 768px) {
 .projects-slider__gallery {
    grid-template-columns: repeat(2, 1fr);
    padding: 15px;
    grid-gap: 15px;
  }
}

.projects-slider__item {
  display: grid;
  position: relative;
  max-width: 270px;
  height: 344px;
  overflow: hidden;
  cursor: pointer;
}

.project-image {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 20px;
  padding-top: 100px;
  transition: opacity 0.3s;
  z-index: 3;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 2);
}

@media screen and (max-width: 4px) {
  .project-name {
    bottom: 40px;
  }
}

.project-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 13.9px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-top: 100px;
  transition: opacity 0.3s;
  z-index: 2;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 2);
}

.projects-slider__item:hover .project-caption,
.projects-slider__item:hover .project-name {
  opacity: 1;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease;
  z-index: 1;
}

.project-overlay:hover {
  background-color: transparent;
}