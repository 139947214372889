.project {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    /* max-width: 1440px;  */
}


.project__photo {
    /* max-width: 1440px; */
    max-height: 686px;
    object-fit: cover;
}

.project__points {
    display: grid;
    border-bottom: 1px solid #E0E0E0;
    background-image: url(../../images/background.png);
    grid-template-areas: 
            'title title'
            'list description';
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
    .project__points {
        display: flex;
        flex-direction: column;
    }
}

.project__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    padding: 50px 135px 50px 135px;
    grid-area: title;
    border-bottom: 1px solid #E0E0E0;
}

@media screen and (max-width: 1090px) {
    .project__title {
        padding: 50px 30px;
    }
}

@media screen and (max-width: 440px) {
    .project__title {
        padding: 50px 16px;
        word-break: break-word;
    }
}

.project__list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    border-right: 1px solid #E0E0E0;
    /* column-gap: 120px; */
    padding-top: 30px;
    padding-left: 135px;
    padding-bottom: 30px;
    list-style: none;   
    grid-area: list; 
    row-gap: 25px;
    align-content: baseline;
}

@media screen and (max-width: 1090px) {
    .project__list {
        padding: 20px 30px;
        border-right: none;
    }
}

@media screen and (max-width: 768px) {
    .project__list {
        border-bottom: 1px solid #E0E0E0;
        padding-bottom: 30px;
        justify-content: space-between;
    } 
}

@media screen and (max-width: 440px) {
    .project__list {
        padding-left: 16px;
        padding-right: 16px;
        column-gap: 10px;
    } 
}

.project__list-points {
    gap: 25px;
    max-width: 220px;
}

.project__list-point {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #6D7781;
    ;
}

@media screen and (max-width: 440px) {
    .project__list-point {
        font-size: 12px;
    }
}

.project__list-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}   

@media screen and (max-width: 440px) {
    .project__list-text {
        font-size: 12px;
    }
}

.project__description {
    max-width: 720px;
    grid-area: description;
    padding-left: 50px;
    padding-right: 135px;
}

@media screen and (max-width: 1090px) {
    .project__description {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 440px) {
    .project__description {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.project__description-point {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    color: #1A1A18;
    padding-top: 30px;
}

@media screen and (max-width: 1090px) {
    .project__description-point {
        padding-top: 20px;
    }
}

@media screen and (max-width: 768px) {
    .project__description-point {
        padding-top: 30px;
    }
}

.project__description-point:last-child {
    padding-bottom: 30px;
}
