.projects__card-list {
    display: grid;
    margin: 0;
    box-sizing: border-box;
    list-style-type: none;
    row-gap: 10px;
    padding: 30px 16px;
    border-bottom: 1px solid #E0E0E0;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  @media screen and (min-width: 768px) {
    .projects__card-list {
      padding: 30px 50px;
    }
  }
  
  @media screen and (min-width: 1090px) {
    .projects__card-list {
      padding: 30px 100px;
    }
  }
  
  @media screen and (min-width: 1280px) {
    .projects__card-list {
      padding: 30px 135px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  
  .projects__card-list-title {
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    grid-column: 1 / -1; /* Центрирование заголовка по всей ширине */
  }
  
  @media screen and (min-width: 768px) {
    .projects__card-list-title {
      font-size: 30px;
    }
  }
  