  .modal-content {
    background-color: #fff;
    padding: 30px 30px 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .modal-title {
    padding-bottom: 20px;
    color: black;
    display: flex;
    justify-content: center;
    font-size: 18px;
  }

  @media screen and (max-width: 440px) {
    .modal-title {
      font-size: 14px;
    }
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    color: black;
  }
  
  input {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .modal-button {
    font-family: Halvar Breitschrift;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #1A1A18;
    background-color: transparent;
    margin: 30px 100px 0;
}

@media screen and (max-width: 440px) {
  .modal-button {
    margin: 30px auto 0;
  }
}

.modal-button:hover {
    border: 1px solid #2A5E38;
    background-color: #2A5E38;
    color: #fff;
    transition: .8s;
}