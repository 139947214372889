@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./Manrope-Medium.woff) format('woff'),
         url(./Manrope-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Manrope';
    font-style:  medium;
    font-weight: 500;
    font-display: swap;
    src: url(./Manrope-Medium.woff2) format("woff2"),
         url(./Manrope-Medium.woff) format("woff");
  }

@font-face {
    font-family: 'Manrope';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url(./Manrope-Bold.woff) format("woff"),
         url(./Manrope-Bold.woff2) format("woff2");
}


@font-face {
    font-family: 'Halvar Breitschrift';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./HalvarBreit-Rg.woff) format("woff"),
         url(./HalvarBreit-Rg.woff2) format("woff2");
}

@font-face {
    font-family: 'Halvar Breitschrift';
    font-style: medium;
    font-weight: 500;
    font-display: swap;
    src: url(./HalvarBreit-Md.woff) format("woff"),
         url(./HalvarBreit-Md.woff2) format("woff2");
}

@font-face {
    font-family: 'Halvar Breitschrift';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url(./HalvarBreit-Bd.woff) format("woff"),
         url(./HalvarBreit-Bd.woff2) format("woff2");
}

@font-face {
    font-family: 'Halvar Breitschrift';
    font-style: black;
    font-weight: 800;
    font-display: swap;
    src: url(./HalvarBreit-XBd.woff) format("woff"),
         url(./HalvarBreit-XBd.woff2) format("woff2");
}