.about-company {
    display: grid;
    grid-template-areas: 
        "title text"
        "logo points"
        "logo button";
    grid-template-columns: 1fr 1fr;
    /* max-width: 1440px; */
    /* margin: 0 auto; */
    background-color: rgb(145, 145, 145);
    /* color: #fff; */
}

@media screen and (max-width: 1090px) {
    .about-company {
        grid-template-areas: 
        'title'
        'text'
        'points'
        'button';
        grid-template-columns: 1fr;
        margin: 0;
    }
}

@media screen and (max-width: 440px) {
    .about-company {
        display: flex;
        flex-direction: column;
    }
}

.about-company__title {
    grid-area: title;
    padding-left: 135px;
    padding-top: 53px;
    padding-bottom: 24px;
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
}

@media screen and (max-width: 1090px) {
    .about-company__title {
        font-size: 28px;
        font-weight: 700;
        line-height: 30.8px;
        padding-top: 30px;
        padding-left: 30px;
        padding-bottom: 34px;
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

@media screen and (max-width: 440px) {
    .about-company__title {
      padding-left: 16px;
    }
  }

.about-company__text {
    grid-area: text;
    font-size: 17px;
    font-weight: 600;
    line-height: 24.59px;
    padding: 50px 135px 50px 50px;
    box-sizing: border-box;
}

@media screen and (max-width: 1090px) {
    .about-company__text {
        font-size: 16px;
        font-weight: 700;
        line-height: 18.53px;
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 20px;
        padding-left: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
}

@media screen and (max-width: 440px) {
    .about-company__text {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

.about-company__logo {
    grid-area: logo;
    background-image: url(../../images/sceleton.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-position: center;
}

@media screen and (max-width: 1090px) {
    .about-company__logo {
        display: none;
    }
}

.about-logo {
    width: 261px;
    padding-top: 50px;
    padding-right: 60px;
}

@media screen and (max-width: 1090px) {
    .about-logo {
        display: none;
    }
}

.about-company__points {
    grid-area: points;
    font-size: 15px;
    font-weight: 600;
    line-height: 21.86px;
    /* max-width: 720px; */
    padding: 50px 135px 39px 50px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    list-style: disc;
}

@media screen and (max-width: 1090px) {
    .about-company__points {
        max-width: fit-content;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.21px;
        padding-top: 20px;
        padding-right: 30px;
        padding-bottom: 20px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 440px) {
    .about-company__points {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

.about-company__point {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.12px;
    opacity: 70%;;
    padding-top: 15px;
    padding-left: 3px;
    list-style: disc;
    margin-left: 20px;
}

.about-company__point:first-child {
    padding-top: 30px;
}

@media screen and (max-width: 1090px) {
    .about-company__point:first-child{
        padding-top: 20px;
    }
}

.buttons {
    grid-area: button;
    padding-bottom: 60px;
    padding-left: 51px;
    display: flex;
}

@media screen and (max-width: 1090px) {
    .buttons {
        padding-bottom: 50px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 440px) {
    .buttons {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.about-company__button-more {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #285E38;
  background-color: #fff;
  border: 1px solid #fff; /* Add border style here */
  padding: 14px 25px;
  grid-area: button;
  text-decoration: none;
  transition: background-color 0.8s, color 0.8s; 
}

@media screen and (max-width: 440px) {
    .about-company__button-more {
        padding: 10px 10px;
    }
}

.about-company__button-more:hover {
  background-color: #2A5E38;
  color: #fff;
}

.about-company__button-ask {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: #fff;
    background-color: #285E38;
    border: 1px solid #fff;
    margin-left: 20px;
    padding-right: 25px;
    padding-left: 25px;
    transition: background-color 0.8s, color 0.8s; 
}

@media screen and (max-width: 440px) {
    .about-company__button-ask {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.about-company__button-ask:hover {
    background-color: #fff;
    color: #285E38;
    border: 1px solid #fff;
}